import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import GlobalConfig, {loadConfig} from "@/js/LocalGlobalSettings";
import IKUtils from "innerken-js-utils";
import hillo from "hillo";

Vue.config.productionTip = false

async function initial() {
    await loadConfig()
    if(IKUtils.getQueryString('Base')){
        hillo.initial(`${location.protocol}//ik${IKUtils.getQueryString('Base')}.ngrok.aaden.io` + '/PHP/')
    }
    i18n.locale = GlobalConfig.lang.toLowerCase()
    new Vue({
        i18n,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
}

initial()
