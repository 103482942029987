<template>
  <v-app>
    <v-app-bar
        app
        flat dense
        dark
    >
      <v-app-bar-title>火锅店备菜系统v1.0.8</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn @click="clearAll" :loading="loading">
          <v-icon left>mdi-delete</v-icon>
          全部清空
        </v-btn>
        <v-btn @click="fillAll" :loading="loading"
               class="ml-4">
          <v-icon left>mdi-check</v-icon>
          全部填满
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <div class="d-flex flex-column"
           style="height: calc(100vh - 48px);background: #f8f8f8">
        <div class="display flex-shrink-1"
             style="overflow: scroll">
          <div class="pa-2" style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 4px">
            <template v-for="dish in filteredDish">
              <v-card @click="textInput=dish.code"
                      :color="dish.dCacheColor.replace('white','green') + ' darken-4'"
                      class="pa-2"
                      flat
                      tile
                      min-height="120px"
                      style="position: relative"
                      dark
                      :key="dish.code">
                <div style="font-size: 22px">{{ dish.code }}.{{ dish.name }}</div>
                <div style="font-size: large;font-weight: bold;position: absolute;left: 8px;bottom: 8px">
                  {{ dish.currentAmount }}/{{
                    dish.threshold
                  }}
                </div>
              </v-card>
            </template>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-card style="height: 400px;display: grid;grid-template-columns: repeat(2,minmax(0,1fr))">
          <div class="pa-4" @click="textInput=''">
            <div class="text-body-1">输入菜品</div>
            <template v-if="textInput">
              <div class="text-truncate text-no-wrap font-weight-black" style="font-size: 64px">
                {{ textInput }}
              </div>
            </template>
            <template v-else>
              <div class="text-truncate text-no-wrap font-weight-black text--disabled"
                   style="font-size: 64px">
                使用扫码枪输入
              </div>


            </template>
            <template v-if="matchedDish">
              <div class="text-body-1 mt-4">菜品信息</div>
              <div style="font-size: 64px">{{ matchedDish.name }}</div>
            </template>

          </div>
          <div v-if="matchedDish">
            <div class="pa-4">
              <div class="text-body-1">当前数量</div>
              <div style="font-size: 56px" class="d-flex align-center text-no-wrap">
                <div> {{ matchedDish.currentAmount }}
                  /<span style="font-weight: bold">
                  {{ matchedDish.threshold }}</span>
                </div>
                <v-spacer></v-spacer>
                <v-card flat
                        class="d-flex"
                        :class="sumCounter>=0?'green--text':'red--text'"
                        v-if="sumCounter!==0" @click="sendModRequest(matchedDish.code,sumCounter)">
                  <div
                      class="text-no-wrap"
                  >
                    {{ sumCounter >= 0 ? '+' : '' }} {{ sumCounter }}
                  </div>
                  <v-icon size="64" class="ml-4">mdi-send</v-icon>
                </v-card>

              </div>
            </div>
            <div class="ml-2">
              <div
                  style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));font-size: 64px">
                <div @click="addMod(1)" class="d-flex align-center justify-center green" style="height: 100px">
                  +
                </div>
                <div @click="addMod(-1)" class="d-flex align-center justify-center red" style="height: 100px">
                  -
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import {
  generalDCacheWrite,
  oneClickClearDCache,
  oneClickFillDCache,
  showCurrentDCacheInfo
} from "@/js/api/api"
import IKUtils from "innerken-js-utils"
import GlobalConfig from "@/js/LocalGlobalSettings"
import { keyBy, orderBy } from 'lodash-es'

const modSignArray = ['+', '-']
const modOptions = [
  1, 2, 5, 10
]
let emptyCodeArr = []

export default {
  name: 'HelloWorld',
  data: () => ({
    textInput: '',
    dishes: [],
    modOptions,
    modSignIndex: parseInt(GlobalConfig.modSignIndex) ?? 0,
    sumCounter: 0,
    timer: null,
    loading: false
  }),

  computed: {
    modSign () {
      return modSignArray[this.modSignIndex]
    },
    filteredDish () {
      return this.dishes
    },

    matchedDish () {
      return this.dishes.find(d => d.code.toLowerCase() === this.textInput.toLowerCase()) ?? null
    }


  },
  watch: {
    modSign () {
      console.log('updateIndex', this.modSignIndex)
      GlobalConfig.updateSettings("modSignIndex", this.modSignIndex === 1 ? 1 : 0)
      this.initialUI()
    }
  },
  methods: {
    submit () {
      if (this.matchedDish) {
        IKUtils.toast(this.sumCounter)
        console.log(this.matchedDish)
        this.sendModRequest(this.matchedDish.code, this.sumCounter)
        this.initialUI()
      } else {
        IKUtils.toast('没找到这个菜', 'error')
        this.textInput = ''
      }

    },

    async sendModRequest (code, count) {
      try {
        if (count > 0 || this.matchedDish.currentAmount > 0) {
          if (count > 0) {
            IKUtils.toast('添加成功！')
          } else {
            IKUtils.toast('减少成功！', 'info')
          }
          await generalDCacheWrite(code, count)
        } else {
          IKUtils.toast("不能把库存减少到零以下", 'error')
          IKUtils.play("fail.wav")
        }
      } catch (e) {
        console.log(e, 'Error on sendModRequest')
      }
      this.initialUI()

    },

    clearAll () {
      this.confirmAndWait(oneClickClearDCache)
    },

    fillAll () {
      this.confirmAndWait(oneClickFillDCache)
    },


    async confirmAndWait (action) {
      this.loading = true
      try {
        const res = await IKUtils.showConfirmAsyn("一旦确定后没办法后悔了哦🙅‍♀️", "你确定吗？")
        if (res.isConfirmed) {
          await action()
        }
      } catch (e) {
        IKUtils.showError(e.message)
      }
      this.initialUI()
      this.loading = false
    },
    initialUI () {
      this.textInput = ''
      this.sumCounter = 0
      this.loadData()
    },
    addMod (count) {

      this.sumCounter += count
      console.log(this.sumCounter)
    },
    async loadData () {
      await this.loadDishInfo()
    },

    async loadDishInfo () {
      this.dishes = orderBy(await showCurrentDCacheInfo(), ['printGroupId', 'displaySort', 'code'], ['asc', 'asc', 'asc'])
      const empty = this.dishes.filter(it => parseInt(it.currentAmount) <= 0).map(it => it.code)
      const fullDisc = keyBy(this.dishes.filter(it => it.currentAmount > 0), 'code')
      console.log(fullDisc)
      emptyCodeArr = emptyCodeArr.filter(it => !fullDisc[it])
      for (const emptyElement of empty) {
        console.log(emptyCodeArr)
        if (!emptyCodeArr.includes(emptyElement)) {
          emptyCodeArr.push(emptyElement)
          IKUtils.play("success.wav")
        }
      }

    },
    input (key) {
      if (key === 'mdi-arrow-left-circle') {
        this.textInput = ''
      } else {
        this.textInput += key
      }
    },

    handle (e) {
      const key = e.key
      switch (key) {
        case 'Enter':
          this.sumCounter = -1
          this.submit()
          break
        case 'Shift':
          break
        default:
          this.textInput += key.toUpperCase()
      }
    }
  },
  async mounted () {
    window.onkeydown = this.handle
    await this.loadData()
    setInterval(async () => {
      if (!this.textInput) {
        await this.loadData()
      }
    }, 10 * 1000)
  }
}
</script>
