import hillo from "hillo"
import GlobalConfig from "@/js/LocalGlobalSettings"


export async function showCurrentDCacheInfo (limit = 100) {
  return (await hillo.get('DCache.php?op=showCurrentDCacheInfo', {lang: GlobalConfig.lang, limit})).content
}

export async function showDCacheLog (sign, limit = 20) {

  const func = sign === '-' ? showDCacheOutputLog : showDCacheInputLog//根据符号判断应该调用哪个函数，可是为啥要根据符号判断用不同函数呢，诡异。
  return (await func(limit)).content
}

async function showDCacheOutputLog (limit = 20) {
  return (await hillo.get('DCache.php?op=showDCacheOutputLog', {lang: GlobalConfig.lang, limit}))
}

async function showDCacheInputLog (limit = 20) {
  return (await hillo.get('DCache.php?op=showDCacheInputLog', {lang: GlobalConfig.lang, limit}))
}

export async function undoDCache (id, sign) {
  return (await hillo.post('DCache.php?op=undoDCache', {id, forInput: sign === '-' ? 0 : 1})).content
}

export async function oneClickClearDCache () {
  return (await hillo.post('DCache.php?op=oneClickClearDCache'))
}

export async function oneClickFillDCache () {
  return (await hillo.post('DCache.php?op=oneClickFillDCache'))
}


export async function generalDCacheWrite (code, amount) {
  const func = amount < 0 ? dCacheOutput : dCacheInput
  //根据符号判断应该调用哪个函数，
  //可是为啥要根据符号判断用不同函数呢，诡异。
  return (await func(code, amount)).content
}

async function dCacheInput (code, amount) {
  return (await hillo.post('DCache.php?op=writeDCacheInput', {code, amount}))
}


async function dCacheOutput (code, amount) {
  return (await hillo.post('DCache.php?op=writeDCacheOutput', {code, amount: -amount}))
}


export async function setDishStatus (targetStatus, ...ids) {
  return await setAllDishStatus(targetStatus, ids)
}


export async function setAllDishStatus (targetStatus = 2, ids) {
  return (await hillo.post('BackendData.php?op=setAllDishStatus', {
    dishStatus: targetStatus, ids: ids.join(',')
  }))
}

